import React from 'react'

export const DefaultButton = ({
  children = '一覧を表示',
  className,
  ...props
}) => {
  return (
    <button type="button" className={className} {...props}>
      {children}
    </button>
  )
}

/**
 *
 */
export const BackButton = ({ children = '戻る', ...props }) => {
  const onClick = () => history.back()

  return (
    <DefaultButton onClick={onClick} {...props}>
      {children}
    </DefaultButton>
  )
}
